import { CATEGORY } from '../types';

export default {
  state: {
    categoryQuery: null,
    childcategoryQuery: null,
  },
  getters: {},
  mutations: {
    [CATEGORY.SET_CATEGORY_QUERY](state, payload) {
      state.categoryQuery = payload;
    },
    [CATEGORY.SET_CHILD_CATEGORY_QUERY](state, payload) {
      state.childcategoryQuery = payload;
    },

  },
  actions: {},
};
