<template>
  <div class="w-full border-t border-gray-100 border-solid border-0 mt-4 pt-3">
    <Table
            :dataSource="currentMessages"
            :columns="columns"
            :pagination="false"
          >

            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'name'">
                <p>{{record.name}}</p>
               <div class="sentence-cell">
                <span class="sentence-text">{{ record.subject }}</span>
                </div>
              </template>

               <template v-if="column.key === 'text'">
               <div class="sentence-cell">
                <span class="sentence-text">{{ record.text }}</span>
                </div>
              </template>

               <template v-if="column.key === 'created_at'">
                    <p> {{ formattedDate(item.created_at) }}</p>
              </template>

              <template v-else-if="column.key === 'action'">
                <div class="flex items-center gap-2">
                    <Button
                      ghost
                      type="primary"
                      size="small"
                      class="rounded shadow flex items-center gap-2"
                      @click="() => handleOpenEdit(record.id)"

                    >
                      <PhEnvelope weight="duotone" :size="16" />
                    </Button>

                    <Button
                      ghost
                      type="primary"
                      size="small"
                      class="rounded shadow flex items-center gap-2"
                      @click="() => handleOpenDetail(record.id)"

                    >
                      <PhEye weight="duotone" :size="16" />
                    </Button>

                  <Popconfirm
                    title="Are you sure delete this Message?"
                    ok-text="Yes"
                    cancel-text="No"
                    @confirm="() => handleDeleteMessage(record.id)"
                  >
                    <Button
                      type="primary"
                      size="small"
                      class="btn-error rounded shadow flex items-center gap-2"
                      :loading="record.id === delSingleLoading"
                    >
                      <PhTrash v-if="record.id !== delSingleLoading" weight="duotone" :size="16" />
                    </Button>
                  </Popconfirm>
                </div>
              </template>
            </template>
          </Table>
    <router-link to="/dashboard/inbox" class="text-center underline block">see more</router-link>
  </div>
</template>

<script>
import { Table } from 'ant-design-vue';

export default {
  components: {
    Table,
  },
  data() {
    return {
      pageNum: 1,
      perPage: 15,
      columns: [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Message',
          dataIndex: 'text',
          key: 'text',
        },
      ],
    };
  },
  watch: {},
  props: {
    currentMessages: {
      type: Object,
      default: null,
    },
  },
  computed: {},
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped></style>
