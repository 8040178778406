import { ROLES } from '../types';

export default {
  state: {
    rolesQuery: null,
    allpermissions: null,
  },
  getters: {},
  mutations: {
    [ROLES.SET_ROLES_QUERY](state, payload) {
      state.rolesQuery = payload;
    },

    [ROLES.SET_PERMISSION_QUERY](state, payload) {
      state.allpermissions = payload;
    },
  },
  actions: {},
};
