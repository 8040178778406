import { UNIVERSITY } from '../types';

export default {
  state: {
    universityQuery: null,
    facultyQuery: null,
    departmentQuery: null,
    classesQuery: null,

  },
  getters: {},
  mutations: {
    [UNIVERSITY.SET_UNIVERSITY_QUERY](state, payload) {
      state.universityQuery = payload;
    },

    [UNIVERSITY.SET_FACULTY_QUERY](state, payload) {
      state.facultyQuery = payload;
    },

    [UNIVERSITY.SET_DEPARTMENT_QUERY](state, payload) {
      state.departmentQuery = payload;
    },

    [UNIVERSITY.SET_CLASSES_QUERY](state, payload) {
      state.classesQuery = payload;
    },
  },
  actions: {},
};
