import Cookies from 'js-cookie';
import { setAxiosHeader } from './apiAxios';
// import { seAdminAxiosHeader } from './apiAdminAxios';

const ID_TOKEN_KEY = 'id_token';
const ADMIN_TOKEN_KEY = 'admin_token';

export const getToken = () => Cookies.get(ID_TOKEN_KEY);

export const saveToken = (token) => {
  Cookies.set(ID_TOKEN_KEY, token);
  setAxiosHeader(token);
};

export const destroyToken = () => {
  Cookies.set(ID_TOKEN_KEY, '');
  Cookies.remove(ID_TOKEN_KEY);
};

export const getAdminToken = () => Cookies.get(ADMIN_TOKEN_KEY);

export const saveAdminToken = (token) => {
  Cookies.set(ADMIN_TOKEN_KEY, token);
  // seAdminAxiosHeader(token);
};

export const destroyAdminToken = () => {
  Cookies.set(ADMIN_TOKEN_KEY, '');
  Cookies.remove(ADMIN_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken, getAdminToken, saveAdminToken, destroyAdminToken };
