<template>
  <div>
    <div v-if="statisticsData" class="flex flex-wrap items-center gap-5 lg:gap-8">

      <router-link to="/dashboard/admins" class="text-center  block">
        <SingleStatistic title="Admins" :value="statisticsData?.total_admins">
            <PhUsers weight="duotone" size="28" />
        </SingleStatistic>
        </router-link>

      <router-link to="/dashboard/category" class="text-center  block">
      <SingleStatistic title="Category" :value="statisticsData?.total_category">
        <PhBriefcase weight="duotone" size="28" />
      </SingleStatistic>
        </router-link>

       <router-link to="/dashboard/books" class="text-center  block">
      <SingleStatistic title="Total Books" :value="statisticsData?.total_books">
        <PhBooks weight="duotone" size="28" />
      </SingleStatistic>
       </router-link>

        <router-link to="/dashboard/blogs" class="text-center  block">
      <SingleStatistic title="Blogs" :value="statisticsData?.total_blogs">
        <PhNewspaper   weight="duotone" size="28" />
      </SingleStatistic>
        </router-link>

      <router-link to="/dashboard/inbox" class="text-center  block">
      <SingleStatistic title="Current Messages" :value="statisticsData?.total_current_messages">
        <PhEnvelopeOpen  weight="duotone" size="28" />
      </SingleStatistic>
      </router-link>
    </div>
  </div>
</template>

<script>
import { PhUsers, PhBriefcase, PhNewspaper, PhBooks, PhEnvelopeOpen } from 'phosphor-vue';

import SingleStatistic from './SingleStatistic.vue';

export default {
  components: {
    SingleStatistic,
    PhUsers,
    PhBriefcase,
    PhNewspaper,
    PhBooks,
    PhEnvelopeOpen,
  },
  data() {
    return {};
  },
  watch: {},
  props: {
    statisticsData: {
      type: Object,
      default: null,
    },
  },
  computed: {},
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped></style>
