<template>
  <div class="w-full border-t border-gray-100 border-solid border-0 mt-4 pt-3">
    <h1> Top Sales Books</h1>
    <Table
            :dataSource="topBookData"
            :columns="columns"
            :pagination="false"
          >

            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'title'">
               <div class="sentence-cell">
                <span class="sentence-text">{{ record.title }}</span>
                </div>
              </template>

               <template v-if="column.key === 'total_sales'">
               <div class="sentence-cell">
                <span class="sentence-text">{{ record.total_sales }}</span>
                </div>
              </template>

               <template v-if="column.key === 'sales_count'">
                    <p> {{ record.sales_count }}</p>
              </template>
            </template>
    </Table>
  </div>
</template>

<script>
import { Table } from 'ant-design-vue';

export default {
  components: {
    Table,
  },
  data() {
    return {
      pageNum: 1,
      perPage: 15,
      columns: [
        {
          title: 'Book',
          dataIndex: 'title',
          key: 'title',
        },
        {
          title: 'Total Sales',
          dataIndex: 'total_sales',
          key: 'total_sales',
        },
        {
          title: 'Sales Count',
          dataIndex: 'sales_count',
          key: 'sales_count',
        },
      ],
    };
  },
  watch: {},
  props: {
    topBookData: {
      type: Object,
      default: null,
    },
  },
  computed: {},
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped></style>
