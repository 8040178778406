<template>
  <div class="w-full border-t border-gray-100 border-solid border-0 mt-4 pt-3">
    <Table :dataSource="currentPlans" :columns="columns" :pagination="false">
      <template #bodyCell="{ column, record }">
        <!-- <template v-if="column.key === 'select'"> <Checkbox /> </template> -->
         <template v-if="column.key === 'created_at'">
          <TypographyText keyboard type="success"
            >{{ record.created_at }}
          </TypographyText>
        </template>
      </template>
    </Table>
    <router-link to="/dashboard/mail_list" class="text-center underline block"
      >see more</router-link
    >
  </div>
</template>

<script>
import { Table, TypographyText } from 'ant-design-vue';

export default {
  components: {
    Table,
    TypographyText,
  },
  data() {
    return {
      pageNum: 1,
      perPage: 15,
      columns: [
        {
          title: 'E-mail',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Date',
          dataIndex: 'created_at',
          key: 'created_at',
        },
      ],
    };
  },
  watch: {},
  props: {
    currentPlans: {
      type: Object,
      default: null,
    },
  },
  computed: {},
  methods: {},
  created() {
  },
};
</script>

<style lang="scss" scoped></style>
