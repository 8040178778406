import { SLIDER } from '../types';

export default {
  state: {
    sliderQuery: null,
  },
  getters: {},
  mutations: {
    [SLIDER.SET_SLIDER_QUERY](state, payload) {
      state.sliderQuery = payload;
    },
  },
  actions: {},
};
