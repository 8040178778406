import { DASH } from '../types';

export default {
  state: {
    dashStatsQuery: null,
    logsQuery: null,
    messageQuery: null,
    settingQuery: null,
    maillistQuery: null,
    deletemessageQuery: null,
    aboutusQuery: null,
  },
  mutations: {
    [DASH.SET_DASH_STATS_QUERY](state, payload) {
      state.dashStatsQuery = payload;
    },
    [DASH.SET_LOGS_QUERY](state, payload) {
      state.logsQuery = payload;
    },
    [DASH.SET_MESSAGE_QUERY](state, payload) {
      state.messageQuery = payload;
    },

    [DASH.SET_DELETED_MESSAGE_QUERY](state, payload) {
      state.deletemessageQuery = payload;
    },

    [DASH.SET_LOGS_QUERY](state, payload) {
      state.logsQuery = payload;
    },
    [DASH.SET_SETTINGS_QUERY](state, payload) {
      state.settingQuery = payload;
    },
    [DASH.SET_MAILLIST_QUERY](state, payload) {
      state.maillistQuery = payload;
    },
    [DASH.SET_ABOUTUS_QUERY](state, payload) {
      state.aboutusQuery = payload;
    },
  },
  actions: {},
};
