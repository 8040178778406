<template>
  <router-view />
</template>

<script>
// import { AUTH } from './store/types';
// import { getToken } from './services/jwt.service';

export default {
  beforeCreate() {

  },
  computed: {

  },
  created() {
  },
  watch: {
  },
  mounted() {},
};
</script>
