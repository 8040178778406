<template>
  <div>
    <div v-if="statisticsDataPublisher" class="flex flex-wrap items-center gap-5 lg:gap-8">

      <router-link to="/dashboard/admins" class="text-center w-[31.3rem] block">
        <SingleStatistic title="Total Book Sales" :value="statisticsDataPublisher?.total_book_sales">
            <PhUsers weight="duotone" size="28" />
        </SingleStatistic>
        </router-link>

      <router-link to="/dashboard/category" class="text-center  w-[31.3rem]  block">
      <SingleStatistic title="Count Book Requests" :value="statisticsDataPublisher?.count_book_requests">
        <PhBriefcase weight="duotone" size="28" />
      </SingleStatistic>
        </router-link>

       <router-link to="/dashboard/books" class="text-center  w-[31.3rem] block">
      <SingleStatistic title="Total Client Request" :value="statisticsDataPublisher?.total_client_request">
        <PhBooks weight="duotone" size="28" />
      </SingleStatistic>
       </router-link>
    </div>
  </div>
</template>

<script>
import { PhUsers, PhBriefcase, PhBooks } from 'phosphor-vue';

import SingleStatistic from './SingleStatistic.vue';

export default {
  components: {
    SingleStatistic,
    PhUsers,
    PhBriefcase,
    PhBooks,
  },
  data() {
    return {};
  },
  watch: {},
  props: {
    statisticsDataPublisher: {
      type: Object,
      default: null,
    },
  },
  computed: {},
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped></style>
