import { BLOG } from '../types';

export default {
  state: {
    blogQuery: null,
  },
  getters: {},
  mutations: {
    [BLOG.SET_BLOG_QUERY](state, payload) {
      state.blogQuery = payload;
    },
  },
  actions: {},
};
