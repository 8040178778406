import { PARTNER } from '../types';

export default {
  state: {
    partnerQuery: null,
  },
  getters: {},
  mutations: {
    [PARTNER.SET_PARTNER_QUERY](state, payload) {
      state.partnerQuery = payload;
    },
  },
  actions: {},
};
