<template>
  <div class="w-full border-t border-gray-100 border-solid border-0 mt-4 pt-3 px-2">
    <Timeline>
      <Timeline-item v-for="item in currentLogs" :key="item.id">
        <template #dot>
          <ClockCircleOutlined />
        </template>
        <span class="text-primary">{{ item.user }}</span>
        {{ item.action }} {{ item.created_at }}</Timeline-item
      >
    </Timeline>
    <router-link to="/dashboard/logs" class="text-center underline block">see more</router-link>
  </div>
</template>

<script>
import { TimelineItem, Timeline } from 'ant-design-vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ClockCircleOutlined } from '@ant-design/icons-vue';

export default {
  components: {
    Timeline,
    TimelineItem,
    ClockCircleOutlined,
  },
  data() {
    return {};
  },
  watch: {},
  props: {
    currentLogs: {
      type: Object,
      default: null,
    },
  },
  computed: {},
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped></style>
